import { AXIOS_INSTANCE } from '@cocoonspace/sdk-js/axios-client/use-custom-instance'
import { getFirebaseApp } from '@cocoonspace/shared/libs/firebase'
import { dateTimeUtils } from '@cocoonspace/shared/utils/date-time-utils'
import { installTwicPics } from '@twicpics/components/react'
import { Analytics } from '@vercel/analytics/react'
import { appWithTranslation, useTranslation } from 'next-i18next'
import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import { Nunito as FontSans } from 'next/font/google'
import Head from 'next/head'
import type { NextPage } from 'next/types'
import { AuthLayout } from '~/components/layouts/auth-layout'
import { AppNavbar } from '~/components/nav-bar/nav-bar'
import { Providers } from '~/components/providers'
import { SeoTags } from '~/components/seo-tags'
import { SiteFooter } from '~/components/templates/site-footer'
import { Toaster } from '~/components/ui/sonner'
import { ROUTES_WITHOUT_FOOTER } from '~/config/routes-without-footer'
import { AuthDialog } from '~/domains/auth/components/auth-dialog'
import { BookingExpirationDialogManager } from '~/domains/booking/components/organisms/booking-expiration-dialog-manager'
import { accessTokenInterceptor } from '~/lib/interceptors/access-token.interceptor'
import { onErrorInterceptor } from '~/lib/interceptors/on-error.interceptor'
import { cn } from '~/lib/utils'

import { APP_CONFIG } from '@cocoonspace/shared/config'
import '@twicpics/components/style.css'
import 'intl-pluralrules'
import '~/styles/globals.css'
import i18nextConfig from '../../next-i18next.config'

const AxeptioCookiesBanner = dynamic(
	() => import('~/components/atoms/axeptio-cookies-banner'),
	{ ssr: false },
)
const MixPanelTracking = dynamic(() => import('~/lib/analytics'), {
	ssr: false,
})
const FrontappChat = dynamic(() => import('~/components/atoms/frontapp-chat'), {
	ssr: false,
})

export type NextPageWithProviders<P = {}, IP = P> = NextPage<P, IP> & {
	providers?: ({ children }: { children: JSX.Element }) => JSX.Element
}

type AppPropsWithProviders = AppProps & {
	Component: NextPageWithProviders
}

// Init firebase
getFirebaseApp({
	appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
	apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
	authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
	storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
	measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
	messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
})

export const fontSans = FontSans({
	subsets: ['latin'],
	variable: '--font-sans',
})

installTwicPics({
	domain:
		process.env.NEXT_PUBLIC_TWICPICS_DOMAIN || 'https://cocoon-space.twic.pics',
	maxDPR: 4,
	path: process.env.NEXT_PUBLIC_TWICPICS_PATH || 'pp',
})

AXIOS_INSTANCE.interceptors.request.use(accessTokenInterceptor)
AXIOS_INSTANCE.interceptors.response.use((res) => res, onErrorInterceptor)

const App = ({ Component, pageProps, router }: AppPropsWithProviders) => {
	const { t } = useTranslation()

	dateTimeUtils.locale(router.locale || 'fr')

	return (
		<main
			className={cn(
				'flex flex-col bg-slate-100 font-sans antialiased',
				fontSans.variable,
			)}
		>
			<Providers pageProps={pageProps}>
				<Head>
					<meta
						key='viewport'
						name='viewport'
						content='width=device-width, initial-scale=1, maximum-scale=1'
					/>

					<meta
						name='apple-itunes-app'
						content={`app-id=${APP_CONFIG.appleAppId}`}
					/>

					{process.env.NEXT_PUBLIC_APP_ENV !== 'prod' && (
						<meta
							name='robots'
							content='noindex, nofollow'
						/>
					)}

					<style>
						{`
							body {
								font-family: ${fontSans.style.fontFamily};
							}
						`}
					</style>
				</Head>

				<SeoTags
					title={process.env.NEXT_PUBLIC_NAME!}
					description={t('cwCommon:seo.metaDescription.paris')}
				/>

				<AppNavbar />

				<AuthLayout>
					<>
						{Component.providers ? (
							<Component.providers>
								<Component {...pageProps} />
							</Component.providers>
						) : (
							<Component {...pageProps} />
						)}

						<BookingExpirationDialogManager />
					</>
				</AuthLayout>

				{!ROUTES_WITHOUT_FOOTER.includes(router.route as any) && <SiteFooter />}

				<AuthDialog />

				<AxeptioCookiesBanner />

				<MixPanelTracking />

				<FrontappChat />

				<Toaster
					position='top-right'
					theme='light'
					toastOptions={{
						style: {
							marginTop: 67,
						},
					}}
				/>
			</Providers>

			<Analytics />
		</main>
	)
}

export default appWithTranslation(App, i18nextConfig as any)
